import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="select-all"
export default class extends Controller {
  static targets = ['button', 'checkbox', 'deselectButton']

  connect() {
  }

  buttonTargetConnected(button) {
    if (button.type === "checkbox") {
      button.addEventListener('change', (e) => {
        if (button.checked === true) {
          this.buttonClicked(e)
        } else {
          this.deselectButtonClicked(e)
        }
      })
    } else {
      button.addEventListener('click', (e) => this.buttonClicked(e))
    }
  }

  deselectButtonTargetConnected(button) {
    button.addEventListener('click', (e) => this.deselectButtonClicked(e))
  }

  buttonClicked(e) {
    e.preventDefault()
    this.checkboxTargets.forEach((checkbox) => checkbox.checked = true)
    /* inform the filter form about the change only once, after all changes have been made */
    this.checkboxTargets[0].form.dispatchEvent(new Event('change'));
  }

  deselectButtonClicked(e) {
    e.preventDefault()
    this.checkboxTargets.forEach((checkbox) => checkbox.checked = false)
    /* inform the filter form about the change only once, after all changes have been made */
    this.checkboxTargets[0].form.dispatchEvent(new Event('change'));
  }
}
