import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = [ "close", "ignoreDirty" ]
  static values = {
    confirmation: String,
    closeUrl: String
  }

  connect() {
    this.dirty = {}
    if(this.closeUrlValue === "") {
      this.closeUrlValue = window.location.href
    }
    this.element.addEventListener("change", (e) => {
      if(!this.ignoreDirtyTargets.includes(e.target)) {
        this.dirty[e.target.closest("turbo-frame").id] = true
      }
    })
    this.element.addEventListener("turbo:submit-start",
      (e) => this.markNotDirty(e.target.closest("turbo-frame").id), false)
    this.element.addEventListener("turbo:frame-render", (e) => this.markNotDirty(e.target.id), false)
    this.bindMouseListeners()
    window.addEventListener("keyup", (e) => this.keyUp(e))
  }

  /* also called from multi line input controller */
  markNotDirty(id) {
    this.dirty[id] = false
  }

  closeTargetConnected(element) {
    element.addEventListener("click", (e) => { e.preventDefault(); this.closeModal() })
    this.reflow(this.element) // slide open effect does not work without this
    this.element.querySelector(".modal").classList.add("modal--open")
  }

  closeOnClick(e) {
    /* Firefox supports e.target === e.currentTarget, but that doesn't work in Chrome */
    if(this.downTarget === e.currentTarget) {
      this.closeModal()
    }
  }

  keyUp(e) {
    if(e.key === "Escape") {
      e.preventDefault()
      e.stopPropagation()
      this.closeModal()
    }
  }

  closeModal() {
    if(!this.anyDirty() || confirm(this.confirmationValue)) {
      let child = this.element.querySelector(".modal")
      if (child) {
        child.classList.remove("modal--open")
        child.addEventListener("transitionend", () => this.element.innerHTML = "")
        Turbo.visit(this.closeUrlValue)
        this.dirty = {}
      }
    }
  }

  anyDirty() {
    for(let key in this.dirty) {
      /* also check if the element is still in the dom, might be removed by the user with a delete action */
      if(this.dirty[key] === true && (this.element.id === key || this.element.querySelector("#" + key) !== null)) {
        return true
      }
    }
    return false
  }

  reflow(element) {
    if(element === undefined) {
      element = document.documentElement
    }
    void(element.offsetHeight)
  }

  bindMouseListeners() {
    this.mouseUpListener = (e) => {
      this.closeOnClick(e)
    }
    this.mouseDownListener = (e) => {
      this.downTarget = e.target
    }
    this.element.addEventListener("mousedown", this.mouseDownListener)
    this.element.addEventListener("mouseup", this.mouseUpListener)
  }

  unbindMouseListeners() {
    this.element.removeEventListener("mousedown", this.mouseDownListener)
    this.element.removeEventListener("mouseup", this.mouseUpListener)
    this.mouseUpListener = undefined
    this.mouseDownListener = undefined
  }
}
