import {Controller} from "@hotwired/stimulus"
import {createPopper} from "@popperjs/core"

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    delay: {
      type: Boolean,
      default: false
    }
  }
  static targets = ["content", "button", "arrow"]

  connect() {
    let interval = undefined
    this.arrowTarget.dataset.popperArrow = ""
    this.popper = createPopper(this.buttonTarget, this.contentTarget, {
      placement: "auto",
      modifiers: [
        {name: "flip", options: {allowedAutoPlacements: ["bottom"]}},
        {name: "offset", options: {offset: [0, 8]}},
      ]
    })

    this.element.addEventListener("mouseenter", (e) => {
      if (this.delayValue) {
        interval = setInterval(() => {
          this.showTooltip()
        }, 500)
      } else {
        this.showTooltip()
      }
    })
    this.element.addEventListener("mouseleave", (e) => {
      if (this.delayValue) {
        clearInterval(interval)
      }
      this.hideTooltip()
    })
  }

  showTooltip() {
    this.element.style.setProperty("--display", "block")
    this.popper.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        {name: "eventListeners", enabled: true},
      ],
    }))

    this.popper.update()
  }

  hideTooltip() {
    this.element.style.setProperty("--display", "none")

    this.popper.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        {name: "eventListeners", enabled: false},
      ],
    }))
  }
}
