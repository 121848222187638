import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-height"
export default class extends Controller {
  connect() {
    this.prevClientHeight = undefined

    this.setViewportProperty()
    window.addEventListener('resize', () => this.setViewportProperty())
  }

  setViewportProperty() {
    let clientHeight = document.documentElement.clientHeight

    if (clientHeight === this.prevClientHeight) {
      return;
    }

    requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--vh', (clientHeight * 0.01) + 'px')
      this.prevClientHeight = clientHeight
    })
  }
}
