import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="slim-select"
export default class extends Controller {
  static targets = ['dropdown', 'button']
  static values = { addAble: Number }

  connect() {
    let changed = false
    this.element.classList.remove("form-select")
    let emptyOption = this.element.querySelector("[value='']")
    if(emptyOption) {
      emptyOption.dataset.placeholder = "true"
    }
    this.slimSelect = new SlimSelect({
      settings: {
        allowDeselect: true,
        placeholderText: "",
        searchPlaceholder: ' ',
        searchText: ' ',
        maxValuesShown: Number.MAX_VALUE
      },
      select: this.dropdownTarget,
      events: {
        beforeOpen: (e) => changed = false,
        afterChange: (e) => {
          changed = true
          /* currently the original change event doesn't bubble up, so we make one ourselves here */
          this.element.dispatchEvent(new Event('change', {bubbles: true}))
        },
        afterClose: (e) => {
          if(!changed) {
            this.element.dispatchEvent(new Event('blur', {bubbles: true}))
          }
        },
        addable: this.addAbleValue === 1 ? (value) => {
          if(parseInt(value) == value) {
            return false
          } else {
            return value
          }
        } : undefined
      }
    })
  }

  buttonTargetConnected(target) {
    target.addEventListener('click', (e) => {
      e.preventDefault()
      this.slimSelect.setSelected(Array.from(this.element.querySelectorAll('option')).map((elem) => {
        return elem.value
      }))
    })
  }
}
