import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = {content: String}

  connect() {
    this.element.addEventListener("click", (e) => {
      e.preventDefault()
      navigator.clipboard.writeText(this.contentValue)
    })
  }
}
