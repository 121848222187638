import {Controller} from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

// Connects to data-controller="clickable-row"
export default class extends Controller {
  static values = {link: String}

  connect() {
    this.element.addEventListener("click", (e) => {
      if (e.target === this.element) {
        Turbo.visit(this.linkValue, {frame: "modal", action: "advance"})
      }
    })
  }
}
