import {Controller} from "@hotwired/stimulus"
import {BarController, BarElement, Chart, Colors, LineController, LineElement, PointElement, CategoryScale,
  TimeScale, LinearScale, Legend, Tooltip, RadarController, RadialLinearScale, Filler} from "chart.js"
import "chartjs-adapter-date-fns"
import {nl, enUS} from "date-fns/locale";


// Connects to data-controller="chart"
export default class extends Controller {
  static values = {
    datasets: Array,
    labels: { type: Array, default: [] },
    timeSeries: { type: Boolean, default: false },
    type: { type: String, default: "line" },
    vertical: String,
    verticalMin: { type: Number, default: 0 }
  }

  connect() {
    Chart.register(Colors, LineController, BarController, BarElement, LineElement, PointElement, CategoryScale,
      TimeScale, LinearScale, Legend, Tooltip, RadarController, RadialLinearScale);
    this.chart = new Chart(this.element,
      {
        type: this.typeValue,
        plugins: this.plugins(),
        data: {
          /* without slice() we won't pass a copy and the next chart rendered has data from the previous chart */
          labels: this.labelsValue.slice(),
          datasets: this.datasetsValue
        },
        options: {
          scales: this.scales(),
          animation: false,
        },
      }
    )
  }

  disconnect() {
    this.chart.destroy() /* without this cleanup, history back will show a 5px height chart */
  }

  scales() {
    if(this.typeValue === "radar") {
      return {r: {ticks: {display: false, beginAtZero: true, max: 10}, min: 0, max: 100,
          pointLabels: {font: {size: 16}}}}
    } else {
      return {x: this.xScaleOptions(), y: this.yScaleOptions()}
    }
  }

  plugins() {
    if(this.typeValue === "radar") {
      return [Filler]
    } else {
      return []
    }
  }

  xScaleOptions() {
    if (this.timeSeriesValue) {
      return {
        type: "time",
        time: {tooltipFormat: "dd-MM-yyyy"},
        adapters: {
          date: {
            locale: document.documentElement.lang === "nl" ? nl : enUS
          }
        }
      }
    } else {
      return {}
    }
  }

  yScaleOptions() {
    return {
      title: { display: true, text: this.verticalValue },
      min: this.verticalMinValue,
      ticks: {callback: (value, index, ticks) => value.toLocaleString(this.localeValue)}
    }
  }
}
