import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-tree"
export default class extends Controller {
  static targets = ['node']

  connect() {
  }

  nodeTargetConnected(node) {
    let card = node.querySelector('.card')
    let icon = ''
    if(node.classList.contains('card-tree__node--open')) {
      icon = 'minus'
    } else if(node.classList.contains('card-tree__node--closed')) {
      icon = 'plus'
    } else {
      return
    }
    card.innerHTML = `<a href="#" class="card-tree__opener" data-action="click->card-tree#toggle">
        <i class="fa fa-light fa-circle-${icon}"></i></a>` + card.innerHTML
  }

  toggle(e) {
    e.preventDefault()
    e.target.classList.toggle('fa-circle-plus')
    e.target.classList.toggle('fa-circle-minus')
    let node = e.target.closest('.card-tree__node')
    node.classList.toggle('card-tree__node--open')
    node.classList.toggle('card-tree__node--closed')
  }
}
