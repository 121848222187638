import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conditional-toggle"
export default class extends Controller {
  static targets = [ 'triggerVisible', 'triggerInvisible', 'toggle' ]
  static values = { className: String }

  connect() {
  }

  triggerInvisibleTargetConnected(target) {
    target.addEventListener('click', (e) => {
      this.toggleTargets.forEach(element => element.classList.add(this.classNameValue))
    })
  }

  triggerVisibleTargetConnected(target) {
    target.addEventListener('click', (e) => {
      this.toggleTargets.forEach(element => element.classList.remove(this.classNameValue))
    })
  }
}
