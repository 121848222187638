import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="inline-edit-form"
export default class extends Controller {
  static targets = ["field"]

  fieldTargetConnected(field) {
    if((field.tagName === "INPUT" && field.type === "text" ) || field.tagName === "TEXTAREA") {
      field.setSelectionRange(field.value.length, field.value.length)
      field.focus()
    } else if(field.slim !== undefined) {
      field.slim.open()
    }
    field.addEventListener("blur", (e) => {
      if(field.isConnected) {
        this.element.requestSubmit()
      }
    })
    field.addEventListener("change", (e) => {
      if(tinymce.get(field.id) || field.type === "date" || field.dataset.controller === "multi-line-input") {
        /* tinymce will fire change far too much, for example on every enter */
        /* input type=date will fire far too much, for example after entering day and month before a valid year */
        /* multi line input controller will already request submit on enter and otherwise blur will handle it */
      } else {
        if(field.isConnected) {
          this.element.requestSubmit()
        }
      }
    })
  }
}
