import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-position"
export default class extends Controller {
  static values = {
    identifier: String,
    horizontal: Boolean,
  }
  connect() {
    let frames = document.querySelectorAll('turbo-frame')
    let position = this.positionToRestore()
    this.stillLoading = frames.length

    if(frames.length === 0) {
      this.restoreScrollPosition(position)
    } else {
      if(position > 0) {
        this.element.style.opacity = 0.2
      }
      frames.forEach((frame) => {
        frame.loaded.then(() => {
          this.stillLoading--
          if(this.stillLoading === 0) {
            this.restoreScrollPosition(position)
            this.element.style.opacity = 1
          }
        })
      })
    }
    window.addEventListener('turbo:before-render', () => this.captureScrollPosition())
  }

  captureScrollPosition() {
    if(window.canvasScrollPosition === undefined) {
      window.canvasScrollPosition = {}
    }
    if(this.horizontalValue) {
      window.canvasScrollPosition[this.identifierValue] = this.element.scrollLeft
    } else {
      window.canvasScrollPosition[this.identifierValue] = this.element.scrollTop
    }
  }

  restoreScrollPosition(value) {
    if(this.horizontalValue) {
      this.element.scrollTo(value, 0)
    } else {
      this.element.scrollTo(0, value)
    }
  }

  positionToRestore() {
    if(window.canvasScrollPosition !== undefined && window.canvasScrollPosition[this.identifierValue] !== undefined) {
      return window.canvasScrollPosition[this.identifierValue]
    }
    return 0
  }
}
