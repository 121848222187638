import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="inline-add-form" and performs three tasks:
// focus first field, close on click outside form and close on esc
export default class extends Controller {
  static targets = ['field', 'close']

  connect() {
    if(this.hasFieldTarget) {
      this.fieldTarget.focus()
    }
    this.eventListener = (e) => {
      this.closeOnClickOutside(e)
    }
    document.addEventListener('click', this.eventListener)
    this.keyListener = (e) => {
      this.keyUp(e)
    }
    this.fieldTargets.forEach((field) => { field.addEventListener('keyup', this.keyListener) })
  }

  disconnect() {
    document.removeEventListener('click', this.eventListener)
    window.removeEventListener('keyup', this.keyListener)
  }

  closeOnClickOutside(e) {
    if(!this.fieldsEmpty()) {
      return
    }
    let current = e.target
    do {
      if (current == this.element) {
        return
      }
      current = current.parentNode
    } while (current != undefined)
    if(this.hasCloseTarget) {
      // click only works after a 0ms timeout
      setTimeout(() => { this.closeTarget.click() }, 0)
    }
  }

  fieldsEmpty() {
    for(let i in this.fieldTargets) {
      if(this.fieldTargets[i].value != '') {
        return false
      }
    }
    return true
  }

  keyUp(e) {
    if(e.key === 'Escape' && this.fieldsEmpty()) {
      e.preventDefault()
      e.stopPropagation()
      this.closeTarget.click()
    }
  }
}
