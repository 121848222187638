import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-enable"
export default class extends Controller {
  static targets = ['radio', 'submit']

  connect() {
    this.change()
    this.radioTargets.forEach(elem => elem.addEventListener('change', (e) => this.change()))
  }

  change() {
    let values = {}
    this.radioTargets.forEach((radio) => {
      let label = document.querySelector('label[for="' + radio.id + '"]')
      let visible = label.offsetWidth > 0 || label.offsetHeight > 0
      if(radio.checked || !visible) {
        values[radio.name] = true
      } else if(values[radio.name] !== true) {
        values[radio.name] = false
      }
    })
    for(let index in values) {
      if(!values[index]) {
        this.submitTarget.disabled = true
        return
      }
    }

    this.submitTarget.disabled = undefined
  }
}
