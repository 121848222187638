import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = [ 'close' ]
  static values = {
    confirmation: String
  }

  connect() {
    this.overviewUrl = window.location.href

    this.element.addEventListener('click', (e) => this.closeOnClick(e))
  }

  closeTargetConnected(element) {
    element.addEventListener('click', (e) => { e.preventDefault(); this.closeSidebar() })
    this.reflow(this.element) // sidebar slide open effect does not work without this
    this.element.classList.add('sidebar--open')
  }

  closeOnClick(e) {
    if(e.target === e.currentTarget) {
      this.closeSidebar()
    }
  }

  closeSidebar() {
    let listener = () => {
      this.element.removeEventListener('transitionend', listener)
      this.element.innerHTML = ''
    }
    this.element.addEventListener('transitionend', listener)
    this.element.classList.remove('sidebar--open')
  }

  reflow(element) {
    if(element === undefined) {
      element = document.documentElement
    }
    void(element.offsetHeight)
  }
}
