import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-line-input"
export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', (e) => this.ignoreNewline(e))
    this.element.addEventListener('input', (_e) => this.resize())
    this.element.style.resize = 'none';
    this.element.style.overflow = 'hidden';
    this.element.style.minHeight = '2.5rem'
    setTimeout(() => this.resize(), 0)
  }

  resize(e) {
    this.element.style.height = 0  /* shrinking won't work without this */
    this.element.style.height = this.element.scrollHeight + 'px'
  }

  ignoreNewline(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      /* use requestSubmit() on form because submit() doesn't trigger Turbo */
      this.element.form.requestSubmit()
    }
  }
}
