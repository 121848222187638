import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["button", "menu"]
  clickHandler = (event) => {
    /* somehow this is activated when opening so it closes directly without this statement */
    if (!this.buttonTarget.contains(event.target)) {
      this.closeMenu()
    }
  }

  connect() {
  }

  buttonTargetConnected(elem) {
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      if (this.menuTarget.classList.contains("toggle-hidden")) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    })
  }

  openMenu() {
    document.addEventListener("click", this.clickHandler);
    this.menuTarget.classList.remove("toggle-hidden")
  }

  closeMenu() {
    document.removeEventListener("click", this.clickHandler);
    this.menuTarget.classList.add("toggle-hidden")
  }
}
