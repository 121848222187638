import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-all"
export default class extends Controller {
  static targets = ['check']

  toggle(e) {
    this.checkTargets.forEach((elem) => elem.checked = e.target.checked)
  }
}
