import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-edit"
export default class extends Controller {
  static targets = ['editable']
  static values = {
    url: String
  }

  connect() {
  }

  editableTargetConnected(element) {
    element.addEventListener('click', (e) => {
      if(e.target.tagName !== 'A') { /* allow normal link behaviour in the editable element */
        this.element.src = this.urlValue
      }
    })
  }
}
