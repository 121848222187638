import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="zoomable-area"
export default class ZoomableArea extends Controller {
  static targets = ["control", "area"]
  static width = 85
  static zoomLevelStorageKey = "zoomableAreaZoomLevel"
  static values = {zoomLevel: {type: Number, default: 0}}

  connect() {
    this.element.parentElement.style.overflow = "auto"
    this.element.style.backgroundColor = this.element.parentElement.backgroundColor
    this.element.addEventListener("wheel", (e) => {
      let mac = /Mac OS/.test(navigator.userAgent)
      if((e.metaKey && mac) || (e.ctrlKey && !mac)) {
        if(e.deltaY > 0) {
          this.zoomLevelValue = Number(this.zoomLevelValue) - 10
        } else if(e.deltaY < 0) {
          this.zoomLevelValue = Number(this.zoomLevelValue) + 10
        }
      }
    })
    window.addEventListener("turbo:load", (e) => {
      this.zoomLevelValueChanged()
    })
  }

  controlTargetConnected(element) {
    element.addEventListener("change", () => {
      this.zoomLevelValue = this.controlTarget.value
    })
  }

  areaTargetConnected(element) {
    element.style.width = `${ZoomableArea.width}rem`
  }

  zoomLevelValueChanged() {
    if(this.zoomLevelValue > 0) {
      this.controlTarget.value = this.zoomLevelValue
      sessionStorage.setItem(ZoomableArea.zoomLevelStorageKey, this.zoomLevelValue)
      this.applyZoom(Number(this.zoomLevelValue) / 100)
    } else {
      let storedValue = Number(sessionStorage.getItem(ZoomableArea.zoomLevelStorageKey))
      if(this.zoomLevelValue === 0) {
        if(storedValue > 0) {
          this.zoomLevelValue = storedValue
        } else {
          this.zoomLevelValue = 100
        }
      }
    }
  }

  applyZoom(zoom) {
    let transformOrigin = [0, 0];
    this.areaTarget.style["transform"] = `scale(${zoom})`
    this.areaTarget.style["transformOrigin"] = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";
    this.areaTarget.style.width = `${zoom * ZoomableArea.width}rem`
  }
}
