import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click-on-enter"
export default class extends Controller {
  static targets = ['button', 'field']
  connect() {
  }

  fieldTargetConnected(field) {
    field.addEventListener('keypress', (e) => {
      if(e.key === 'Enter') {
        e.preventDefault()
        this.buttonTarget.click()
      }
    })
  }
}
