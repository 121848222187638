import {Controller} from "@hotwired/stimulus"
import Pickr from '@simonwep/pickr';

// Connects to data-controller="color-picker"
export default class extends Controller {
  connect() {
    const swatches = [
      '#dd140a',
      '#f1a923',
      '#eede56',
      '#1cb273',
      '#3bcbfd',
      '#a862bb',
      '#f16ba0',
    ]
    this.element.style.backgroundColor = 'var(--pcr-color)'
    this.element.style.color = '#fff'
    if (this.element.value === '') {
      this.element.value = swatches[0]
    }

    Pickr.create({
        el: this.element,
        useAsButton: true,
        comparison: false, /* save on chose */
        theme: 'nano',
        default: this.element.value,
        components: {

          // Main components
          preview: true,
          opacity: false,
          hue: true,

          // Input / output Options
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: false
          }
        },
        swatches: swatches
      }
    ).on('change', (color) => {
      this.element.value = color.toHEXA()
    })
  }
}
