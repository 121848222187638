import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-buttons"
export default class ScrollButtons extends Controller {
  static targets = ['container']
  static get OFFSET() {
    return 3 * 16 * 2; /* grid has auto columns of 3rem */
  }

  connect() {
  }

  containerTargetConnected(container) {
    container.addEventListener('scroll', (e) => {
      this.containerTargets.forEach((target) => {
        if (e.target !== target) {
          target.scrollTo(e.target.scrollLeft, 0)
        }
      })
    })
  }

  scrollLeft(e) {
    e.preventDefault()
    this.containerTarget.scrollTo(this.containerTarget.scrollLeft - ScrollButtons.OFFSET, 0)
  }

  scrollRight(e) {
    e.preventDefault()
    this.containerTarget.scrollTo(this.containerTarget.scrollLeft + ScrollButtons.OFFSET, 0)
  }
}
