import {Controller} from "@hotwired/stimulus"
import Rails from "rails-ujs"

const day = 60 * 60 * 24 * 1000
// Connects to data-controller="gantt-task"
export default class GanttTaskController extends Controller {
  static targets = ['resizer', 'cell']
  static values = {
    start: String,
    end: String,
    url: String,
    readOnly: Boolean
  }
  static dayFormatter = new Intl.DateTimeFormat('en-us', {day: 'numeric'})
  static monthFormatter = new Intl.DateTimeFormat('en-us', {month: 'numeric'})
  static yearFormatter = new Intl.DateTimeFormat('en-us', {year: 'numeric'})

  connect() {
    this.stopDragHandler = (e) => this.stopDrag(e)
    this.dragHandler = (e) => this.drag(e)
    this.stopResizeHandler = (e) => this.stopResize(e)
    this.resizeHandler = (e) => this.resize(e)
    this.element.addEventListener('mousedown', (e) => this.mouseDown(e))
  }

  mouseDown(e) {
    if (!this.readOnlyValue && e.target === this.resizerTarget) {
      this.resizing()
    } else if(!this.readOnlyValue) {
      this.mouseDownDate = this.dateAt(e.x, e.y)
      let taskStart = new Date(this.startValue + 'T00:00:00Z')
      let taskEnd = new Date(this.endValue + 'T00:00:00Z')
      if(isNaN(taskStart) || isNaN(taskEnd)) {
        this.fillMissingDate(this.mouseDownDate, taskStart, taskEnd);
      } else if(this.format(this.floorDate(taskStart)) <= this.format(this.mouseDownDate) &&
          this.format(this.mouseDownDate) <= this.format(this.ceilDate(taskEnd))) {
        this.dragDateOffsetStart = (this.mouseDownDate - taskStart) / day
        this.dragDateOffsetEnd = (taskEnd - this.mouseDownDate) / day
        document.addEventListener('mouseup', this.stopDragHandler)
        this.element.addEventListener('mousemove', this.dragHandler)
      }
    } else {
      /* only mouseup allowed, no edits just open the modal */
      document.addEventListener('mouseup', this.stopDragHandler)
    }
  }

  fillMissingDate(clickDate, startDate, endDate) {
    let date = this.offsetDate(clickDate, 0)
    if(isNaN(startDate) && !isNaN(endDate) && this.format(clickDate) <= this.format(endDate)) {
      this.startValue = this.format(clickDate)
      this.resizing()
    } else if(!isNaN(startDate) && isNaN(endDate) && this.format(clickDate) >= this.format(startDate)) {
      this.endValue = this.format(this.ceilDate(date))
      this.resizing()
    } else if(isNaN(startDate) && isNaN(endDate)) {
      this.startValue = this.format(clickDate)
      this.endValue = this.format(this.ceilDate(date))
      this.resizing()
    }
  }

  resizing() {
    document.addEventListener('mouseup', this.stopResizeHandler)
    this.element.addEventListener('mousemove', this.resizeHandler)
  }

  stopDrag(e) {
    document.removeEventListener('mouseup', this.stopDragHandler)
    this.element.removeEventListener('mousemove', this.dragHandler)

    if(this.readOnlyValue || this.format(this.mouseDownDate) === this.format(this.dateAt(e.x, e.y))) {
      document.querySelector(`a[data-turbo-frame="modal"][href="${this.urlValue}"]`).click()
    } else {
      this.save()
    }
  }

  drag(e) {
    let currentDate = this.dateAt(e.x, e.y)
    if(isNaN(currentDate)) {
      return
    } else if(this.format(currentDate) !== this.format(this.mouseDownDate)) {
      this.mouseDownDate = new Date(1, 0, 0); /* reset click detection when there was a move outside of original date */
    }
    /* +3/-3 to account for cases like 1st of February + 30 days which would not become 28th of February */
    this.startValue = this.format(this.floorDate(this.offsetDate(currentDate, -1 * this.dragDateOffsetStart + 3)))
    this.endValue = this.format(this.ceilDate(this.offsetDate(currentDate, this.dragDateOffsetEnd - 3)))
  }

  offsetDate(currentDate, offset) {
    let clone = new Date(this.format(currentDate) + 'T00:00:00Z')
    return new Date(clone.getTime() + offset * day)
  }

  stopResize(e) {
    document.removeEventListener('mouseup', this.stopResizeHandler)
    this.element.removeEventListener('mousemove', this.resizeHandler)
    this.save()
  }

  resize(e) {
    /* will skip cells at end of month without -1 */
    let newDate = this.offsetDate(this.dateAt(e.x, e.y), -1)
    if(!isNaN(newDate) && newDate >= new Date(this.startValue)) {
      this.endValue = this.format(this.ceilDate(newDate))
    }
  }

  save() {
    fetch(this.urlValue, {
      method: 'PATCH',
      body: JSON.stringify({
        ogsm_card: {
          start: this.startValue,
          due: this.endValue
        },
        kind: 'action'
      }),
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        'Content-Type': 'application/json',
        'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
      }
    }).then(response => response.text().then(data => {
      if(response.headers.get('content-type').indexOf('text/vnd.turbo-stream.html') !== -1) {
        Turbo.renderStreamMessage(data)
      }
    }))
  }

  startValueChanged() {
    this.drawTask()
  }

  endValueChanged() {
    this.drawTask()
  }

  dateAt(x, y) {
    return new Date(document.elementFromPoint(x, y).dataset.date + 'T00:00:00Z')
  }

  drawTask() {
    let node = document.createTextNode(this.titleValue)
    if(this.startValue === '' && this.endValue === '') {
      this.resizerTarget.style.display = 'none'
      return
    } else {
      if(this.endValue !== '') {
        this.resizerTarget.style.display = 'block'
      } else {
        this.resizerTarget.style.display = 'none'
      }
    }
    let taskStart = new Date(this.startValue + 'T00:00:00Z')
    let taskEnd = new Date(this.endValue + 'T00:00:00Z')
    if((taskEnd - taskStart) / day < 14) {
      taskStart = this.floorDate(taskStart)
      taskEnd = this.ceilDate(taskEnd)
    }
    if(taskEnd.getDate() === 1) {
      taskEnd = this.offsetDate(taskEnd, -1)
    }
    if(taskStart.getDate() >= 30) {
      taskStart = this.offsetDate(taskStart, 1)
      if(taskStart.getDate() !== 1) {
        taskStart = this.offsetDate(taskStart, 1)
      }
    }
    this.cellTargets.forEach((cell) => {
      let cellStart = new Date(cell.dataset.date + 'T00:00:00Z')
      /* 3 days later for rounding with February (28) vs 30/31 */
      let cellEnd = this.ceilDate(this.offsetDate(cellStart, 3))
      if (!isNaN(taskStart) && !isNaN(taskEnd)) {
        if (this.format(taskStart) >= this.format(cellStart) && this.format(taskStart) < this.format(cellEnd) ||
            this.format(taskStart) <= this.format(cellStart) && this.format(taskEnd) >= this.format(cellEnd) ||
            this.format(taskEnd) > this.format(cellStart) && this.format(taskEnd) <= this.format(cellEnd)) {
          cell.classList.add('gantt__cell--busy')
        } else {
          cell.classList.remove('gantt__cell--busy')
        }
      }
      if (!isNaN(taskStart) &&
          this.format(taskStart) >= this.format(cellStart) &&
          this.format(taskStart) < this.format(cellEnd)) {
        cell.classList.add('gantt__cell--busy-start')
      } else {
        cell.classList.remove('gantt__cell--busy-start')
      }
      if(!isNaN(taskEnd) &&
          this.format(taskEnd) > this.format(cellStart) &&
          this.format(taskEnd) <= this.format(cellEnd)) {
        cell.classList.add('gantt__cell--busy-end')
        cell.append(this.resizerTarget)
      } else {
        cell.classList.remove('gantt__cell--busy-end')
      }
    })
  }

  /* round to 1st or 15th of month, used for first date of a task */
  floorDate(current) {
    let clone = new Date(current.getTime());
    if(clone.getDate() < 15) {
      clone.setDate(1)
    } else {
      clone.setDate(15)
    }
    return clone
  }

  /* round to 15th or last day of month, used for last date of a task */
  ceilDate(current) {
    let clone = new Date(current.getTime());
    if(clone.getDate() < 15) {
      clone.setDate(15)
    } else {
      /* 0 is last day of month */
      clone = new Date(clone.getFullYear(), clone.getMonth() + 1, 0, 0, 0);
    }

    return clone
  }

  format(date) {
    return `${GanttTaskController.yearFormatter.format(date)}-${
      GanttTaskController.monthFormatter.format(date).toString().padStart(2, '0')}-${
      GanttTaskController.dayFormatter.format(date).toString().padStart(2, '0')}`
  }
}
