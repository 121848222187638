import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lazy-load-print"
export default class extends Controller {
  static targets = ["icon"]

  connect() {
    this.element.addEventListener("click", (e) => {
      e.preventDefault()
      this.changeIconToLoading()
      this.printAfterEagerLoad()
    })
  }

  printAfterEagerLoad() {
    let lazyLoadedLeft = document.querySelectorAll('turbo-frame').length

    document.querySelectorAll('turbo-frame').forEach((elem) => {
      elem.removeAttribute("loading")
      elem.loaded.then(() => lazyLoadedLeft--)
    })

    let interval = setInterval(() => {
      if(lazyLoadedLeft === 0) {
        clearInterval(interval)
        window.print()
        this.changeIconToPrint()
      }
    }, 100)
  }

  changeIconToLoading() {
    this.iconTarget.classList.remove("fa-print")
    this.iconTarget.classList.add("fa-spinner", "fa-spin-pulse")
  }

  changeIconToPrint() {
    this.iconTarget.classList.add("fa-print")
    this.iconTarget.classList.remove("fa-spinner", "fa-spin-pulse")
  }
}
