import {Controller} from "@hotwired/stimulus"
import {ArcElement, Chart, Colors, DoughnutController} from 'chart.js'

// Connects to data-controller="doughnut-chart"
export default class extends Controller {
  static values = {
    datasets: Array,
    centerText: String,
  }

  connect() {
    Chart.register(ArcElement, Colors, DoughnutController);

    this.chart = new Chart(this.element,
      {
        type: 'doughnut',
        data: {
          datasets: this.datasetsValue
        },
        options: {
          animation: false,
          cutout: '65%',
          hover: { mode: null }
        },
        plugins: [{
          beforeDraw: (chart) => {
            chart.ctx.restore()
            chart.ctx.font = '1rem sans-serif'
            chart.ctx.textBaseline = 'middle'
            let textX = Math.round((chart.width - chart.ctx.measureText(this.centerTextValue).width) / 2)
            let textY = chart.height / 2
            chart.ctx.fillText(this.centerTextValue, textX, textY)
            chart.ctx.save()
          }
        }]
      }
    )
  }

  disconnect() {
    this.chart.destroy() /* without this cleanup, history back will show a 5px height chart */
  }
}
