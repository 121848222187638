import "@hotwired/turbo-rails"
import "./controllers"
/* expose Rails API for tinymce file upload callback */
import Rails from 'rails-ujs'
window.Rails = Rails;

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

/* re-init beacon to fix styling issues when turbo frame target _top is used */
document.addEventListener('turbo:before-render', function() {
  window.Beacon && window.Beacon('destroy')
  window.customBeaconInit && window.customBeaconInit(false)
})
