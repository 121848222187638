import {Controller} from "@hotwired/stimulus"
import Rails from "rails-ujs";

// Connects to data-controller="auto-save"
export default class extends Controller {
  static values = {
    url: String,
    attachableType: String,
    attachableId: Number
  }

  connect() {
    this.element.addEventListener("change", (e) => this.changed())
    this.element.addEventListener("keyup", (e) => this.changed())
  }

  changed() {
    if (this.interval !== undefined) {
      clearTimeout(this.interval)
    }
    this.interval = setTimeout(() => {
      this.save()
    }, 1000)
  }

  save() {
    const form = new FormData(this.element);
    fetch(this.urlValue, {
      method: "POST",
      body: form,
      headers: {
        'X-CSRF-Token': Rails.csrfToken(),
        'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
      }
    })
      .then(response => response.text().then(data => {
        if (response.headers.get('content-type').indexOf('text/vnd.turbo-stream.html') !== -1) {
          Turbo.renderStreamMessage(data)
        } else {
          this.element.outerHTML = data
        }
      }))
  }
}
