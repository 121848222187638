import {Controller} from "@hotwired/stimulus"
import Rails from "rails-ujs"
import Sortable from "sortablejs";

export default class extends Controller {
  static values = {
    rowOrderField: String,
    batchSortUrl: String,
    handle: String,
    group: { type: String, default: "drag" },
    confirmationText: { type: String, default: "" }
  }

  connect() {
    Sortable.create(this.element, {
      group: this.groupValue,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      emptyInsertThreshold: 16,
      invertedSwapThreshold: 0.5,
      forceAutoScrollFallback: true,
      scrollSensitivity: 120,
      scrollSpeed: 30,
      filter: ".sortable-no-drag",
      handle: this.handleActive(),
      preventOnFilter: false, // normal browser behaviour for dragging in not draggable events
      onEnd: (evt) => {
        // this is inverted logic, we can't use the Stimulus value because that is "from", we need "to"
        let confirmText = evt.to.dataset.dragConfirmationTextValue
        // ask for confirmation only when the texts differ, i.e. when the type is actually changed
        if(evt.from === evt.to || !confirmText || confirmText === this.confirmationTextValue || confirm(confirmText)) {
          this.drop(evt)
        } else {
          if (evt.oldIndex >= evt.from.children.length) {
            evt.from.appendChild(evt.item)
          } else {
            evt.from.insertBefore(evt.item, evt.from.children[evt.oldIndex])
          }
        }
      }
    })
    document.addEventListener("dragover", function(e) { e.preventDefault() }) /* prevent revert animation on macOS */
  }

  handleActive() {
    let finePointer = window.matchMedia("(hover: hover) and (pointer: fine");
    if(!finePointer["matches"]) {
      return this.handleValue
    } else {
      return undefined
    }
  }

  drop(evt) {
    /* hide possibly expanded columns in the dropped item till the ajax response arrives */
    evt.item.classList.add("sortable-chosen")
    if(evt.to.dataset.dragParams !== undefined) {
      fetch(evt.item.dataset.dragUrl, {
        method: "PATCH",
        body: evt.to.dataset.dragParams,
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml"
        }
      })
        .then(response => response.text().then(data => {
          if(response.headers.get("content-type").indexOf("text/vnd.turbo-stream.html") !== -1) {
            Turbo.renderStreamMessage(data)
          } else {
            evt.item.outerHTML = data
          }
        }))
    }
    if(evt.item.dataset.ignoreOrder !== "true") {
      this.updateOrder(Array.from(evt.to.children))
    }
  }

  updateOrder(elements) {
    let collection = ""
    elements.forEach((el) => {
      let element_index = elements.indexOf(el)
      if(el.dataset.batchSort !== undefined) {
        collection += el.dataset.batchSort + element_index
      } else if(el.dataset.dragUrl !== undefined) {
        let body = this.rowOrderFieldValue + "=" + element_index

        fetch(el.dataset.dragUrl, {
          method: "PATCH",
          body: body,
          headers: {"X-CSRF-Token": Rails.csrfToken(), "Content-Type": "application/x-www-form-urlencoded"}
        })
      }
    })

    if(collection.length > 1) {
      fetch(this.batchSortUrlValue, {
        method: "PATCH",
        body: collection,
        headers: {"X-CSRF-Token": Rails.csrfToken(), "Content-Type": "application/x-www-form-urlencoded"}
      })
    }
  }
}
