import {Controller} from "@hotwired/stimulus"
import tinymce from "tinymce/tinymce.min"
import "tinymce/icons/default/icons.min"
import "tinymce/themes/silver/theme.min"
import "tinymce/models/dom/model.min"

// Connects to data-controller="tinymce"
// Used to initialize tinymce when it is added through a turbo stream change or turbo frame render, because there
// currently are no events that can be hooked to.
export default class extends Controller {
  static values = {
    simple: Boolean,
    focus: Boolean,
  }

  connect() {
    if (tinymce.get(this.element.id) !== null) {
      tinymce.get(this.element.id).remove()
    }
    if (this.simpleValue) {
      tinymce.init({
        ...this.globalSettings(), ...{
          toolbar: "bold italic bullist numlist link unlink code image insertattachment"
        },
        plugins: "autoresize lists link image"
      })
    } else {
      tinymce.init(this.globalSettings())
    }
  }

  tinymceSetup(editor) {
    editor.on('change', (e) => {
      /* copy editor content to original element so handlers watching it can also read what was changed */
      editor.targetElm.innerHTML = editor.getContent()
      editor.targetElm.dispatchEvent(new Event('change', {bubbles: true}))
    })
    editor.on('keyup', (e) => {
      /* copy editor content to original element so handlers watching it can also read what was changed */
      editor.targetElm.innerHTML = editor.getContent()
      editor.targetElm.dispatchEvent(new Event('keyup', {bubbles: true}))
    })
    editor.on('blur', (e) => {
      e.target.targetElm.dispatchEvent(new Event('blur', {bubbles: true}))
    })
    editor.on('init', () => {
      document.dispatchEvent(new Event('tinymce-initialized'))
      let fieldset = editor.getElement().closest('fieldset')
      if (fieldset !== null && fieldset.disabled) {
        editor.mode.set("readonly");
      }
      /* NOTE: this can only be accessed through the dom as we don't have access to the controller instance */
      if (this.targetElm.dataset.tinymceFocusValue === "true") {
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false)
        editor.focus()
      }
    })

    editor.ui.registry.addButton('insertAttachment', {
      icon: 'upload',
      tooltip: 'Insert attachment',
      onAction: (_) => {
        tinymce.activeEditor.execCommand('mceLink')
        // click only works after a 0ms timeout
        setTimeout(() => document.querySelector('button.tox-browse-url').click(), 0)
      }
    })
  }

  globalSettings() {
    return {
      license_key: 'gpl',
      target: this.element,
      statusbar: false,
      menubar: false,
      branding: false,
      relative_urls: false,
      remove_script_host: false,
      images_upload_url: '/tinymce_assets', // for automatic_uploads
      image_uploadtab: false, // prefer custom file picker icon
      images_upload_credentials: true,

      file_picker_types: 'file image media',
      file_picker_callback: (callback, value, meta) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        if (meta.filetype === 'image') {
          input.setAttribute('accept', 'image/*');
        }
        input.addEventListener('change', function (e) {
          let file = e.target.files[0];
          let resultMeta = {text: file.name};
          if (meta.filetype === 'image') {
            resultMeta = {alt: file.name};
          }

          let formData = new FormData;
          let xhr = new XMLHttpRequest();
          formData.append('file', file);

          xhr.addEventListener('load', function (event) {
            let result = JSON.parse(xhr.response);
            callback(result.location, resultMeta);
          });
          xhr.open('POST', tinymce.activeEditor.getParam('images_upload_url', ''));
          Rails.CSRFProtection(xhr);
          xhr.send(formData);
        });

        input.click();
      },
      setup: this.tinymceSetup,
      toolbar: "undo redo bold italic bullist numlist outdent indent removeformat link unlink image insertattachment",
      plugins: "autoresize link code image table lists",
      content_style: 'img { max-width: 100%; height: auto; } a { color: var(--text-color); }',
      link_target_list: false,
      link_default_target: '_blank',
      link_title: false,
      link_assume_external_targets: 'https',
      language: document.documentElement.lang,
      base_url: "/assets/tinymce"
    }
  }
}
