import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "trigger", "toggle" ]

  connect() {
  }

  triggerTargetConnected(elem) {
    elem.addEventListener("click", (e) => {
      if(elem.href === window.location.href) {
        e.preventDefault()
      }
      this.toggleTargets.forEach(element => element.classList.toggle("toggle-hidden"))
    })
  }

  show() {
    this.toggleTargets.forEach(element => element.classList.remove("toggle-hidden"))
  }
}
